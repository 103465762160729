/* You can add global styles to this file, and also import other style files */

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  box-sizing: inherit;
  line-height: 1.6;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  overscroll-behavior-y: none;
}
